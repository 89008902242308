.budget-container {
    border-radius: 5px;
    margin: 0px;
    color: white;
}


.budget-info {
    display: flex;
    flex-direction: row;
    align-items: center;
    margin-top: 40px;
}



.button-container {
    display: flex;
    flex-direction: row; 
    margin-top: 0px; 
    margin-bottom: 30px;
}

table {
    border: 7px solid #374050;
    border-top: none;
}

#expenses {
  text-align: left;
  width: 50px;
}
#amount {
    width: 50px;
}
#description{
    width: 100px;
}
#total {
    font-weight: bold;
    padding-top: 10px;
}

/**
    fund color stuff. cant seem to get to work correctly
*/
.green {
    color: green; 
}

.negative {
    color: red; 
}

/*all logo stuff*/
.logo {
    text-align: left;
    margin-top: 0px;
    margin-bottom: 20px;
}

.stud {
    font-size: 40px;
    font-weight: bold;
    color: #007bff; 
}

.bud {
    font-size: 40px;
    font-weight: bold;
    color: darkblue; 
}
