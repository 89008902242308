.slide-in {
    animation-name: slide-in-anim;
    animation-duration: 0.5s;
    animation-fill-mode: forwards;
    animation-timing-function: ease-out;
}

.slide-in-1 {
    animation-name: slide-in-anim;
    animation-duration: 0.5s;
    animation-fill-mode: forwards;
    animation-timing-function: ease-out;
    animation-delay: 0s;
    opacity: 0;
}

.slide-in-2 {
    animation-name: slide-in-anim;
    animation-duration: 0.5s;
    animation-fill-mode: forwards;
    animation-timing-function: ease-out;
    animation-delay: 0.5s;
    opacity: 0;
}

.slide-in-3 {
    animation-name: slide-in-anim;
    animation-duration: 0.5s;
    animation-fill-mode: forwards;
    animation-timing-function: ease-out;
    animation-delay: 1s;
    opacity: 0;
}

.slide-in-up-4 {
    animation-name: slide-in-up-anim;
    animation-duration: 0.5s;
    animation-fill-mode: forwards;
    animation-timing-function: ease-out;
    animation-delay: 1.5s;
    opacity: 0;
}

@keyframes slide-in-anim {
    from {
        opacity: 0;
        transform: translate(-50%);
    }

    to {
        opacity: 1;
        transform: translate(0%);
    }
}

@keyframes slide-in-up-anim {
    from {
        opacity: 0;
        transform: translateY(-200%);
    }

    to {
        opacity: 1;
        transform: translate(0%);
    }
}
